import { ExpandCard } from './expand-card.tsx'
import type { EventInfo } from '~/model/api.ts'
import { useTranslation } from 'react-i18next'
import type { PointRoute } from '@rouvydev/web-components/content'
import { EventCard } from '@rouvydev/web-components/cards'
import { NormalizedLink } from '~/components/normalized-link.tsx'
import { ThumborUrlBuilder } from 'thumbor-url-builder-ts'
import {
  convertMetersToKm,
  useUnitConversion,
} from '~/utils/unit-conversions.ts'

const thumbor = new ThumborUrlBuilder(ENV.THUMBOR_SECURITY, ENV.THUMBOR_URL)

export function EventInfoCard(
  props: EventInfo & {
    official?: boolean
    displayStartDateTime?: string
    expand?: boolean
    className?: string
  },
) {
  const { t } = useTranslation(['collections'])
  const { convertDistance, convertElevation } = useUnitConversion()

  const defaultSession = props.sessions[0]
  const url = `${ENV.RIDERS_PORTAL_URL}/events/${defaultSession.id}`

  const routePoints = props.route.simplifiedGeometryPoints?.map(
    point =>
      ({
        distance: point?.distance,
        altitude: point?.altitude,
      }) as PointRoute,
  )

  const img =
    props.mainPhoto?.data?.attributes?.url ??
    props.coverPhoto?.data?.attributes?.url

  const imageUrl = img
    ? thumbor.setImagePath(encodeURIComponent(img)).buildUrl()
    : undefined

  return (
    <ExpandCard
      card={
        <EventCard
          event={{
            title: props.title,
            image: imageUrl,
            link: <NormalizedLink to={url} />,
            startDateTime: props.displayStartDateTime ?? '',
            type: props.type,
            distance: convertDistance(
              convertMetersToKm(props.route.distanceMeters),
            ),
            elevation: convertElevation(props.route.ascendedMeters),
            coins: defaultSession.coinsForCompletion,
            routeGeometry: routePoints,
          }}
          flags={{
            official: props.official,
            joined: false,
            drafting: props.isDrafting,
            smartTrainers: props.smartTrainersOnly,
          }}
          stats={{
            registeredCount: props.registrationsCount,
          }}
          strings={{
            joined: t('joined'),
            recommended: t('recommended'),
          }}
          useFixedSize
        />
      }
      expand={props.expand === true}
      expandedTitle={props.expandTitle ?? props.title}
      expandedDescription={props.description ?? undefined}
      link={url}
      className={props.className}
    />
  )
}
