import type { HeaderAcceptInviteFragment } from '~/model/api.ts'
import { Image } from '~/components/image.tsx'
import { useSearchParams } from '@remix-run/react'
import { Cols } from '~/components/cols.tsx'
import { buttonVariants } from '@rouvydev/web-components/basics'
import { cn } from '@rouvydev/web-components/utils'

export function prepareHeaderAcceptInviteProps(
  data: HeaderAcceptInviteFragment,
) {
  return {
    id: data.id,
    backgroundImage: data.acceptInviteBackgroundImage,
    button: data.acceptInviteButton,
    buttonFallback: data.acceptInviteButtonFallback,
    heading: data.acceptInviteHeading,
    headingFallback: data.acceptInviteHeadingFallback,
    subheading: data.acceptInviteSubheading,
    subheadingFallback: data.acceptInviteSubheadingFallback,
  }
}

type Props = ReturnType<typeof prepareHeaderAcceptInviteProps>

export function HeaderAcceptInvite(props: Props) {
  const [searchParams] = useSearchParams()
  const {
    backgroundImage,
    button,
    buttonFallback,
    heading,
    headingFallback,
    subheading,
    subheadingFallback,
  } = props

  const referralId = searchParams.get('referralId')
  const fallback = !referralId
  const headingDisplay = fallback && headingFallback ? headingFallback : heading
  const subheadingDisplay =
    fallback && subheadingFallback ? subheadingFallback : subheading

  const buttonDisplay = {
    link:
      fallback && buttonFallback?.link
        ? buttonFallback.link
        : `${button.link}?hash=${referralId}`,
    text: fallback && buttonFallback?.text ? buttonFallback.text : button.text,
  }

  return (
    <div>
      <div className="relative -mb-20 xl:-mb-32">
        {[
          'radial-gradient(58.3% 178.8% at 46.9% -51.44%, rgba(111, 16, 231, 0.4) 0%, rgba(124, 16, 231, 0) 100%)',
          'radial-gradient(143.44% 205.05% at -98.67% -120.51%, rgba(252, 205, 0, 0.4) 0%, rgba(252, 205, 0, 0) 100%)',
        ].map((gradient, index) => (
          <div
            key={index}
            className="absolute inset-0"
            style={{
              background: gradient,
              mixBlendMode: 'normal',
              transform: 'matrix(-1, 0, 0, 1, 0, 0)',
            }}
          />
        ))}
        <div className="absolute inset-0">
          <Image
            className="block h-full w-full object-cover"
            src={backgroundImage?.data?.attributes?.url}
            formats={backgroundImage.data?.attributes?.formats}
          />
        </div>
        <div className="container relative">
          <div className="z-10 flex h-[632px] flex-col items-center justify-center gap-4 md:h-[608px] md:gap-6 lg:h-[800px] lg:gap-8 lg:pt-[120px]">
            <Cols size="8">
              <h3 className="text-center font-display text-display-40 font-extrabold uppercase italic leading-[40px] text-text-default md:text-left md:text-display-60 md:leading-[60px] lg:text-[4.5rem] lg:leading-[72px]">
                {headingDisplay}
              </h3>
            </Cols>
            <Cols size="6">
              <p className="text-body-16 font-semibold text-text-muted md:text-body-18 lg:text-body-20">
                {subheadingDisplay}
              </p>
            </Cols>

            <a
              className={cn(
                buttonVariants({
                  variant: 'primary',
                }),
                'justify-center',
              )}
              href={buttonDisplay.link}
            >
              {buttonDisplay.text}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
