import type { NewsletterCtaFragment } from '~/model/api.ts'
import { Button } from '@rouvydev/web-components/basics'
import { Image } from '~/components/image.tsx'
import { Input } from '~/components/input.tsx'
import { useFetcher } from '@remix-run/react'
import { conform, useForm } from '@conform-to/react'
import { getFieldsetConstraint, parse } from '@conform-to/zod'
import { z } from 'zod'
import type { action } from '~/routes/resources.newsletter.ts'
import { ErrorList } from '~/components/errors-list.tsx'
import { useTranslation } from 'react-i18next'
import { Alert } from '~/components/alert.tsx'
import { EmailSchema } from '~/utils/schema.ts'
import type { RenderableTreeNodes } from '@markdoc/markdoc'
import { Cols } from '~/components/cols.tsx'
import { trackEvent } from '~/utils/gtm.client.ts'

type TransformedNewsletterCta = {
  transformedHint: RenderableTreeNodes
} & NewsletterCtaFragment

export function prepareNewsletterCtaProps(data: NewsletterCtaFragment) {
  const transformedObject = data as TransformedNewsletterCta
  return {
    id: data.id,
    description: data.description,
    hint: transformedObject.transformedHint,
    campaignId: data.campaignId,
    title: data.ctaTitle,
    buttonLabel: data.newsletterCtaButtonLabel,
    visual: data.visual,
    anchor: data.newsletterCtaAnchor,
  }
}

export const NewsletterSchema = z.object({
  email: EmailSchema,
  campaignId: z.string(),
})

type Props = ReturnType<typeof prepareNewsletterCtaProps>

export function NewsletterCta(props: Props) {
  const { t } = useTranslation()
  const { title, description, buttonLabel, visual, campaignId } = props
  const fetcher = useFetcher<typeof action>()

  const [form, fields] = useForm({
    id: 'newletter-form',
    constraint: getFieldsetConstraint(NewsletterSchema),
    defaultValue: { email: '' },
    lastSubmission: fetcher.data?.submission,
    onValidate({ formData }) {
      return parse(formData, { schema: NewsletterSchema })
    },
    shouldRevalidate: 'onBlur',
    onSubmit(_, { formData }) {
      trackEvent('email_captured', {
        campaignId: campaignId,
        formType: 'newsletter',
        capturedEmail: formData.get('email'),
      })
    },
  })

  const busy = fetcher.state !== 'idle'

  return (
    <div id={props?.anchor ?? undefined}>
      <div className="py-20 md:py-10">
        <div className="container">
          <div className="flex flex-col items-center gap-10 xl:flex-row xl:gap-8">
            <div className="order-2 w-full xl:order-1">
              <Cols size="5">
                <div className="flex flex-col gap-4">
                  <h3 className="text-center font-display text-body-32 font-extrabold uppercase italic text-text-default md:text-display-40 xl:text-left xl:text-display-48">
                    {title}
                  </h3>
                  <p className="block-description text-center xl:text-left">
                    {description}
                  </p>
                  {form.errors.length > 0 && (
                    <ErrorList
                      className="pb-4"
                      errors={form.errors}
                      id={form.errorId}
                    />
                  )}

                  {fetcher.data?.status === 'success' && (
                    <Alert
                      closeable={false}
                      message={t('newsletter_subscribed')}
                    />
                  )}
                  {fetcher.data?.status === 'error' && (
                    <Alert
                      variant="warning"
                      closeable={false}
                      message={t('newsletter_subscription_error')}
                    />
                  )}
                  <fetcher.Form
                    action="/resources/newsletter"
                    {...form.props}
                    method="POST"
                  >
                    <input type="hidden" name="campaignId" value={campaignId} />
                    <div className="flex flex-col items-center gap-4 xl:flex-row">
                      <div className="w-full">
                        <Input
                          {...conform.input(fields.email)}
                          className="w-full"
                          placeholder={t('enter_email')}
                        />
                      </div>
                      <Button
                        type="submit"
                        className="w-full min-w-[140px] justify-center xl:w-auto"
                        variant="primary"
                        disabled={busy}
                      >
                        {busy ? t('loading') : buttonLabel}
                      </Button>
                    </div>
                    <ErrorList
                      errors={
                        fields.email.errors?.length
                          ? fields.email.errors
                          : ['\u00A0']
                      }
                    />
                  </fetcher.Form>
                </div>
              </Cols>
            </div>
            <div className="order-1 w-full xl:order-2">
              <Cols size="7" className="flex xl:justify-end">
                {visual?.data?.attributes?.url && (
                  <div className="mx-auto flex h-[214px] w-full items-center justify-center bg-newsletter-radial xl:h-[560px]">
                    <div className="h-[126px] xl:h-[329px]">
                      <Image
                        src={visual?.data?.attributes?.url}
                        alt={visual?.data.attributes.alternativeText ?? ''}
                        width={visual?.data.attributes.width ?? 500}
                        height={visual?.data.attributes.height ?? 500}
                        formats={visual.data.attributes.formats}
                        className="h-full w-full object-cover"
                      />
                    </div>
                  </div>
                )}
              </Cols>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
