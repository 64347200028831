import type { CompanionAppCtaFragment } from '~/model/api.ts'
import { NormalizedLink } from '~/components/normalized-link.tsx'
import { buttonVariants } from '@rouvydev/web-components/basics'
import { Image } from '~/components/image.tsx'
import type { RenderableTreeNodes } from '@markdoc/markdoc'
import { Markdown } from '~/components/markdown.tsx'

type TransformedCompanionAppCta = {
  transformedDescription: RenderableTreeNodes
} & CompanionAppCtaFragment

export function prepareCompanionCtaProps(data: CompanionAppCtaFragment) {
  const transformedObject = data as TransformedCompanionAppCta

  return {
    id: data.id,
    anchorId: data.companionAppCtaAnchor,
    title: data.companionAppCtaTitle,
    description: transformedObject.transformedDescription,
    image: data.companionAppCtaImage,
    buttons: data.buttons,
  }
}

type Props = ReturnType<typeof prepareCompanionCtaProps>

export function CompanionCta(props: Props) {
  const { anchorId, title, description, buttons, image } = props
  return (
    <div id={anchorId ?? undefined}>
      <div className="py-20 md:py-24">
        <div className="container">
          <div className="flex flex-col items-center gap-x-8 md:flex-row">
            {image?.data?.attributes?.url && (
              <div className="w-auto md:w-[752px]">
                <Image
                  src={image?.data?.attributes?.url}
                  alt={image?.data.attributes.alternativeText ?? ''}
                  width={image?.data.attributes.width ?? 500}
                  height={image?.data.attributes.height ?? 500}
                  formats={image?.data.attributes.formats}
                  className="h-full w-full object-contain"
                />
              </div>
            )}

            <div>
              <h3 className="font-display text-body-32 font-extrabold uppercase italic text-text-default md:text-display-40 xl:text-display-48">
                {title}
              </h3>
              <div className="block-description mt-4 text-center md:mt-6 md:text-left">
                <Markdown content={description} />
              </div>

              {buttons?.length && (
                <div className="mt-4 flex flex-wrap items-center justify-center gap-4 md:mt-6 md:justify-start">
                  {buttons.map((button, index) => {
                    if (!button?.link) {
                      return null
                    }
                    return (
                      <NormalizedLink
                        className={buttonVariants({
                          variant: 'primary',
                        })}
                        key={index}
                        to={button.link}
                      >
                        {button?.text}
                      </NormalizedLink>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
