import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '~/components/carousel.tsx'
import { cn } from '@rouvydev/web-components/utils'
import type { WorkoutsSectionFragment } from '~/model/api.ts'
import { WorkoutCard } from '~/components/collections/workout-card.tsx'

function prepareWorkoutsSectionProps(data: WorkoutsSectionFragment) {
  return {
    title: data.workoutsTitle,
    anchor: data.workoutsAnchor,
    workouts: data.workouts?.list,
    expand: data.expand,
  }
}
type Props = ReturnType<typeof prepareWorkoutsSectionProps>
function WorkoutsSection({ title, anchor, workouts, expand }: Props) {
  return workouts?.length ? (
    <div className="relative bg-global-dark-ink-blue-200">
      <div id={anchor ?? undefined}>
        <div className="flex flex-col gap-20 py-20">
          <h3 className="block-subtitle container">{title}</h3>

          <Carousel
            className="px-4"
            opts={{
              dragFree: true,
            }}
          >
            <CarouselContent>
              {workouts.map((workout, index) => (
                <CarouselItem
                  key={index}
                  className={cn('basis-auto pl-8', index === 0 && 'pl-0')}
                >
                  <WorkoutCard
                    key={workout.id}
                    {...workout}
                    expand={expand === true}
                    className="bg-global-dark-ink-blue-300"
                  />
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
          </Carousel>
        </div>
      </div>
    </div>
  ) : null
}

export { WorkoutsSection, prepareWorkoutsSectionProps }
