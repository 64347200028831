import type { PageNavigationFragment } from '~/model/api.ts'
import { buttonVariants } from '@rouvydev/web-components/basics'
import { cn } from '@rouvydev/web-components/utils'
import { Gradient } from '~/components/gradients.tsx'
import { NormalizedLink } from '~/components/normalized-link.tsx'
import type { RenderableTreeNodes } from '@markdoc/markdoc'
import { Markdown } from '~/components/markdown.tsx'

type TransformedPageNavigation = {
  transformedDescription: RenderableTreeNodes
} & PageNavigationFragment

export function preparePageNavigationProps(data: PageNavigationFragment) {
  const tranformedObject = data as TransformedPageNavigation

  return {
    id: data.id,
    description: tranformedObject.transformedDescription,
    title: data.pageNavTitle,
    buttons: data.buttons?.map(button => ({
      link: button?.link,
      style: button?.buttonStyle,
      type: button?.buttonType,
      text: button?.text,
      ctaEvent: button?.ctaEvent,
    })),
    anchor: data.pageNavAnchor,
  }
}

type Props = ReturnType<typeof preparePageNavigationProps>

export function PageNavigation(props: Props) {
  const { title, description, buttons } = props

  return (
    <div id={props?.anchor ?? undefined}>
      <div className="relative overflow-hidden pb-16 pt-16 md:pt-32">
        <Gradient />
        <div className="container">
          <div className="flex flex-col items-center">
            <h2 className="block-heading mb-6 text-center">{title}</h2>
            <div className="block-description text-center">
              <Markdown content={description} />
            </div>
            {buttons?.length ? (
              <div className="mt-10 flex flex-wrap items-center justify-center gap-6 xl:mt-14">
                {buttons.map((button, index) => (
                  <NormalizedLink
                    className={cn(
                      buttonVariants({
                        size: 'medium',
                        variant: button.style ?? 'alternative',
                      }),
                      button.style,
                    )}
                    key={index}
                    to={button.link ?? ''}
                  >
                    {button.text}
                  </NormalizedLink>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}
