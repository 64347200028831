import type { ComponentSharedCard, HighlightsFragment } from '~/model/api.ts'
import { Enum_Componentsharedhighlights_Layout } from '~/model/api.ts'
import { buttonVariants } from '@rouvydev/web-components/basics'
import { cn } from '@rouvydev/web-components/utils'
import type { RenderableTreeNodes } from '@markdoc/markdoc'
import { Image } from '~/components/image.tsx'
import { Markdown } from '~/components/markdown.tsx'
import { Cols } from '~/components/cols.tsx'
import { NormalizedLink } from '~/components/normalized-link.tsx'

type TransformedContentCard = {
  transformedContentText: RenderableTreeNodes
} & ComponentSharedCard

export function prepareHighlightsProps(data: HighlightsFragment) {
  return {
    id: data.id,
    highlightsAnchor: data.highlightsAnchor,
    highlight: data.Highlight?.length
      ? data.Highlight.map(h => {
          const transformedItem = h as TransformedContentCard

          return {
            button: h?.button,
            buttonAlt: h?.buttonAlt,
            image: h?.contentImage,
            text: transformedItem.transformedContentText,
            title: h?.contentTitle,
          }
        })
      : [],
    highlightsTitle: data.highlightsTitle,
    layout: data.Layout,
  }
}

type Props = ReturnType<typeof prepareHighlightsProps>

const layoutIndexMap: Record<Enum_Componentsharedhighlights_Layout, number> = {
  [Enum_Componentsharedhighlights_Layout.AlternateLeft]: 2,
  [Enum_Componentsharedhighlights_Layout.AlternateRight]: 1,
  [Enum_Componentsharedhighlights_Layout.Left]: -1,
  [Enum_Componentsharedhighlights_Layout.Right]: -2,
}

export function Highlights(props: Props) {
  const { highlight, highlightsTitle, layout, highlightsAnchor } = props
  const layoutIndex = (layout && layoutIndexMap[layout]) ?? 1

  if (highlight.length === 0) {
    return null
  }

  function imgClassName(index: number) {
    return layoutIndex > 0
      ? (index + layoutIndex) % 2 === 0
        ? 'lg:order-1'
        : 'lg:order-10'
      : layoutIndex === -1
        ? 'lg:order-1'
        : 'lg:order-3'
  }

  function contentClassName(index: number) {
    return index % 2 === 1 ? 'lg:order-1' : 'lg:order-2'
  }

  return (
    <div>
      <div id={highlightsAnchor ?? undefined}>
        {highlightsTitle && (
          <h3 className="block-heading pt-20 text-center xl:pt-24">
            {highlightsTitle}
          </h3>
        )}
        <div className="divide-y divide-border-secondary-subtle">
          {highlight.map((h, index) => (
            <div key={index}>
              <div className="container py-20 md:py-10">
                <div
                  className={cn(
                    'flex flex-col items-center gap-10 lg:flex-row xl:gap-8',
                    imgClassName(index),
                  )}
                >
                  <Cols
                    size="7"
                    className={cn(imgClassName(index), 'max-lg:order-last')}
                  >
                    {h.image?.data?.attributes?.url && (
                      <Image
                        src={h.image.data.attributes.url}
                        alt={h.image.data.attributes.alternativeText ?? ''}
                        width={h.image?.data.attributes.width ?? 750}
                        height={h.image?.data.attributes.height ?? 750}
                        formats={h.image?.data.attributes.formats}
                        className="block aspect-square object-contain"
                      />
                    )}
                  </Cols>
                  <Cols
                    size="5"
                    className={cn(
                      contentClassName(index),
                      'flex flex-col items-center gap-4 py-6 max-lg:order-first lg:items-start xl:gap-6 xl:py-0',
                    )}
                  >
                    <h4 className="text-center font-display text-body-32 font-extrabold uppercase italic text-text-default lg:text-left xl:text-display-48">
                      {h.title}
                    </h4>
                    <div className="block-description text-center lg:text-left">
                      <Markdown content={h.text} />
                    </div>
                    <div className="flex w-full flex-wrap justify-center gap-4 lg:justify-start">
                      {h.button?.link && (
                        <NormalizedLink
                          to={h.button.link}
                          className={cn(
                            buttonVariants({
                              variant: h.button.buttonStyle ?? 'alternative',
                            }),
                            'w-full justify-center md:w-min',
                          )}
                        >
                          {h.button.text}
                        </NormalizedLink>
                      )}
                      {h.buttonAlt?.link && (
                        <NormalizedLink
                          to={h.buttonAlt.link}
                          className={cn(
                            buttonVariants({
                              variant: h.buttonAlt.buttonStyle ?? 'alternative',
                            }),
                            'w-full justify-center md:w-min',
                          )}
                        >
                          {h.buttonAlt.text}
                        </NormalizedLink>
                      )}
                    </div>
                  </Cols>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
