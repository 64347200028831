import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

type ConvertFunction = (
  value: number | string | null,
  decimalPlaces?: number,
  addUnit?: boolean,
  unit?: string,
) => string

type NumberToStringFunction = (
  numericValue: number,
  decimalPlaces?: number,
) => string

type UnitConversionHook = {
  convertSpeed: ConvertFunction
  convertElevation: ConvertFunction
  convertDistance: ConvertFunction
  convertHeight: ConvertFunction
  convertMetersPerSecondToMilesPerHour: ConvertFunction
  formatValue: ConvertFunction
  formatNumberToString: NumberToStringFunction
  speedUnit: string
  elevationUnit: string
  distanceUnit: string
  heightUnit: string
  isImperial: boolean
}

export function useUnitConversion(
  isImperial: boolean = false,
): UnitConversionHook {
  const { i18n } = useTranslation()

  const formatNumberToString: NumberToStringFunction = useCallback(
    (numericValue: number, decimalPlaces: number = 0) => {
      return new Intl.NumberFormat(i18n.language, {
        minimumFractionDigits: 0,
        maximumFractionDigits: decimalPlaces,
        useGrouping: true,
      }).format(numericValue)
    },
    [i18n.language],
  )

  const convertAndFormat = useCallback(
    (
      value: number | string | null,
      conversionFactor: number,
      unit: string,
      decimalPlaces: number = 2,
      addUnit: boolean = true,
    ): string => {
      if (value === null) return '0'

      let numericValue = typeof value === 'string' ? parseFloat(value) : value
      numericValue = isImperial ? numericValue * conversionFactor : numericValue

      const formattedValue = formatNumberToString(numericValue, decimalPlaces)

      return addUnit ? `${formattedValue}\u00A0${unit}` : formattedValue
    },
    [formatNumberToString, isImperial],
  )

  const speedUnit = isImperial ? 'mph' : 'km/h'
  const elevationUnit = isImperial ? 'ft' : 'm'
  const distanceUnit = isImperial ? 'mi' : 'km'
  const heightUnit = isImperial ? 'in' : 'cm'

  const convertSpeed: ConvertFunction = useCallback(
    (value, decimalPlaces = 1, addUnit = true) => {
      return convertAndFormat(
        value,
        0.621371,
        speedUnit,
        decimalPlaces,
        addUnit,
      )
    },
    [speedUnit, convertAndFormat],
  )

  const convertElevation: ConvertFunction = useCallback(
    (value, decimalPlaces = 2, addUnit = true) => {
      return convertAndFormat(
        value,
        3.28084,
        elevationUnit,
        decimalPlaces,
        addUnit,
      )
    },
    [elevationUnit, convertAndFormat],
  )

  const convertDistance: ConvertFunction = useCallback(
    (value, decimalPlaces = 2, addUnit = true) => {
      return convertAndFormat(
        value,
        0.621371,
        distanceUnit,
        decimalPlaces,
        addUnit,
      )
    },
    [distanceUnit, convertAndFormat],
  )

  const convertHeight: ConvertFunction = useCallback(
    (value, decimalPlaces = 2, addUnit = true) => {
      return convertAndFormat(
        value,
        0.393701,
        heightUnit,
        decimalPlaces,
        addUnit,
      )
    },
    [convertAndFormat, heightUnit],
  )

  const convertMetersPerSecondToMilesPerHour: ConvertFunction = useCallback(
    (value, decimalPlaces = 2, addUnit = true) => {
      const conversionFactor = 3600 / 1609.34
      return convertAndFormat(
        value,
        conversionFactor,
        isImperial ? 'm/s' : 'mph',
        decimalPlaces,
        addUnit,
      )
    },
    [convertAndFormat, isImperial],
  )

  /**
   * For units like Kj, Cadence, and Watts that don't change, the functions remain without conversion
   * and just format the input to the required number of decimal places and add the unit if necessary.
   */
  const formatValue: ConvertFunction = useCallback(
    (value, decimalPlaces = 2, addUnit = false, unit?: string) => {
      if (value === null) return '0'
      const numericValue = typeof value === 'string' ? parseFloat(value) : value
      const formattedValue = numericValue.toFixed(decimalPlaces)
      return addUnit && unit ? `${formattedValue} ${unit}` : formattedValue
    },
    [],
  )

  return {
    convertMetersPerSecondToMilesPerHour,
    convertSpeed,
    convertElevation,
    convertDistance,
    convertHeight,
    formatValue,
    formatNumberToString,
    speedUnit,
    elevationUnit,
    heightUnit,
    distanceUnit,
    isImperial,
  }
}

export const convertMetersToKm = (m: number) => (m / 1000).toFixed(2)
