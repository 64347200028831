export const priceFormatRaw = (
  price: number | undefined,
  currency_code: string,
  forceDecimals = false,
) => {
  const { locale, display } = getLocaleByCurrencyCode(currency_code)

  if (typeof price === 'number') {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency_code,
      currencyDisplay: display,
      maximumFractionDigits: forceDecimals
        ? 2
        : currency_code === 'CZK' || price % 100 === 0
          ? 0
          : 2,
    }).format(price / 100)
  }
  return ''
}

// CZK, USD, EUR
const getLocaleByCurrencyCode = (currency_code: string) => {
  switch (currency_code) {
    case 'CAD':
      return { locale: 'en-CA', display: 'code' }
    case 'USD':
      return { locale: 'en-US', display: 'code' }
    case 'EUR':
      return { locale: 'de-DE', display: 'symbol' }
    case 'CZK':
      return { locale: 'cs-CZ', display: 'symbol' }
    case 'GBP':
      return { locale: 'en-GB', display: 'symbol' }
    default:
      return { locale: 'en-US', display: 'code' }
  }
}
