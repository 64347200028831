const gradients = [
  'radial-gradient(68.59% 42.96% at 59.48% 51.67%, rgba(111, 16, 231, 0.28) 0%, rgba(124, 16, 231, 0) 100%)',
  'radial-gradient(205.26% 69.95% at -113.52% 110.03%, rgba(111, 16, 231, 0.28) 0%, rgba(124, 16, 231, 0) 100%)',
  'radial-gradient(78.44% 67.23% at 61.9% -39.19%, rgba(79, 170, 255, 0.4) 0%, rgba(79, 118, 255, 0) 100%)',
  'radial-gradient(207.19% 86.66% at 226.85% 141.09%, rgba(79, 170, 255, 0.4) 0%, rgba(79, 118, 255, 0) 100%)',
  'radial-gradient(84.79% 53.55% at 163.39% 58.83%, rgba(200, 44, 245, 0.4) 0%, rgba(205, 44, 245, 0) 100%)',
]

export function DynamicZoneSectionGradients() {
  return (
    <>
      {gradients.map((gradient, index) => (
        <div
          key={index}
          className="absolute -z-10 h-full w-full"
          style={{
            background: gradient,
          }}
        ></div>
      ))}
    </>
  )
}
