import type {
  SpotlightsListFragment,
  SpotlightsListItemFragment,
} from '~/model/api.ts'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '~/components/carousel.tsx'
import { cn } from '@rouvydev/web-components/utils'
import { SpotlightCard } from '~/components/collections/spotlight-card.tsx'

function prepareSpotlightsListProps(data: SpotlightsListFragment) {
  return {
    title: data.spotlightsListTitle,
    anchor: data.spotlightsListAnchor,
    spotlights: data.spotlights?.data,
  }
}

type Props = ReturnType<typeof prepareSpotlightsListProps>

function SpotlightsList({ title, anchor, spotlights }: Props) {
  return (
    <div className="container bg-global-dark-ink-blue-200">
      <div className="relative overflow-hidden" id={anchor ?? undefined}>
        <div className="py-20 md:py-24">
          <div className="container pb-24">
            <h3 className="text-center font-display text-[2.5rem] font-extrabold uppercase italic text-text-default lg:text-[4.5rem]">
              {title}
            </h3>
          </div>
          {spotlights?.length ? (
            <Carousel
              className="px-4"
              opts={{
                dragFree: true,
              }}
            >
              <CarouselContent className="lg:justify-center">
                <CarouselItem className="basis-[calc((100%-80rem)/2)] " />
                {spotlights?.map((spotlight, index) => (
                  <CarouselItem
                    key={index}
                    className={cn(index === 0 && 'pl-0', 'basis-[336px]')}
                  >
                    <SpotlightCard
                      {...(spotlight.attributes as SpotlightsListItemFragment)}
                    />
                  </CarouselItem>
                ))}
              </CarouselContent>
              <CarouselPrevious />
              <CarouselNext />
            </Carousel>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export { prepareSpotlightsListProps, SpotlightsList }
