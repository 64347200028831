import { useTranslation } from 'react-i18next'
import { NormalizedLink } from '~/components/normalized-link.tsx'
import { isAfter, isBefore } from 'date-fns'
import { ChallengeCard as SharedChallengeCard } from '@rouvydev/web-components/cards'
import { ExpandCard } from '~/components/collections/expand-card.tsx'
import { ThumborUrlBuilder } from 'thumbor-url-builder-ts'

const thumbor = new ThumborUrlBuilder(ENV.THUMBOR_SECURITY, ENV.THUMBOR_URL)

export function ChallengeCard({
  logoOriginal,
  challengeId,
  className,
  title,
  startDateTime,
  endDateTime,
  registeredCount,
  experience,
  expand,
  description,
  expandTitle,
  coins,
  startCountdown,
  endCountdown,
}: {
  id: string
  challengeId: string
  logoOriginal?: string
  className?: string
  title: string
  startDateTime: string
  endDateTime: string
  registeredCount: number
  experience: number
  expand: boolean
  description?: string
  expandTitle?: string
  coins?: number
  startCountdown?: string
  endCountdown?: string
}) {
  const { t } = useTranslation(['collections'])
  const url = `${ENV.RIDERS_PORTAL_URL}/challenges/${challengeId}`

  const currentDate = new Date()
  const startDate = new Date(startDateTime)
  const endDate = new Date(endDateTime ?? '')
  const isUpcoming = isBefore(currentDate, startDate)
  const isPast = isAfter(currentDate, endDate)
  const image = logoOriginal
    ? thumbor.setImagePath(encodeURIComponent(logoOriginal)).buildUrl()
    : undefined

  const date = isUpcoming
    ? `${t('Starts_in')} ${startCountdown}`
    : isPast
      ? `${t('Ended')} ${endCountdown}`
      : `${t('Ends_in')} ${endCountdown}`

  return (
    <ExpandCard
      card={
        <SharedChallengeCard
          title={title}
          registeredCount={registeredCount}
          targetDateDistance={date}
          isDone={isPast}
          joined={false}
          useFixedSize
          segments={[]}
          strings={{
            joined: t('joined'),
            recommended: t('recommended'),
            done: t('done'),
          }}
          experience={experience}
          coins={coins}
          image={image}
          className={className}
          link={<NormalizedLink to={url} />}
        />
      }
      link={url}
      expand={expand}
      expandedTitle={expandTitle ?? title}
      expandedDescription={description}
      className={className}
    />
  )
}
