import { secondsToHours } from '~/utils/date'

export function averageWorkloadPerWeek(
  totalSeconds: number,
  totalDays: number,
) {
  return totalDays != 0 ? secondsToHours(totalSeconds / (totalDays / 7)) : 0
}

export function durationInWeeks(totalDays: number) {
  return totalDays != 0 ? Math.ceil(totalDays / 7) : 0
}
