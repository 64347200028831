export function formatDuration(secs: number) {
  const hours = Math.floor(secs / 3600)
  const minutes = Math.floor(secs / 60) % 60
  const seconds = Math.floor(secs % 60)
  return [hours, minutes, seconds].map(v => (v < 10 ? '0' + v : v)).join(':')
}

export function secondsToHours(seconds: number) {
  return Math.ceil(seconds / 3600)
}
