import { NormalizedLink } from '~/components/normalized-link.tsx'
import { cn } from '@rouvydev/web-components/utils'
import { buttonVariants } from '@rouvydev/web-components/basics'
import { useTranslation } from 'react-i18next'
import type { ReactElement } from 'react'

type Props = {
  card: ReactElement
  className?: string
  link: string
  expand: boolean
  expandedTitle: string
  expandedDescription?: string
}

export function ExpandCard({
  card,
  className,
  link,
  expand = false,
  expandedTitle,
  expandedDescription,
}: Props) {
  const { t } = useTranslation()

  return (
    <div
      className={cn(
        'flex flex-col bg-global-dark-ink-blue-200 lg:flex-row',
        className,
      )}
    >
      {card}
      {expand && (
        <div className="flex h-80 w-[266px] flex-col justify-between gap-4 bg-global-dark-ink-blue-300 px-5 py-8 text-left md:w-[288px] lg:h-auto lg:w-[42rem] lg:gap-6 lg:p-8">
          <div className="flex flex-col gap-4 lg:gap-6">
            <h4 className="line-clamp-2 font-display text-display-28 font-extrabold uppercase italic leading-8 text-text-default lg:text-body-32">
              {expandedTitle}
            </h4>
            <p className="h-36 overflow-hidden text-body-14 font-semibold text-text-muted lg:h-40 lg:text-body-20">
              <span className="line-clamp-5">{expandedDescription}</span>
            </p>
          </div>

          <NormalizedLink
            to={link}
            className={cn(
              buttonVariants({ variant: 'alternative', size: 'small' }),
              'w-fit',
            )}
          >
            {t('more_info')}
          </NormalizedLink>
        </div>
      )}
    </div>
  )
}
