import type { HeaderVideoFragment } from '~/model/api.ts'
import { Cols } from '~/components/cols.tsx'
import { conform, useForm } from '@conform-to/react'
import { getFieldsetConstraint, parse } from '@conform-to/zod'
import { Input } from '~/components/input.tsx'
import { Button, buttonVariants } from '@rouvydev/web-components/basics'
import { ErrorList } from '~/components/errors-list.tsx'
import { useFetcher } from '@remix-run/react'
import type { action } from '~/routes/resources.newsletter.ts'
import { z } from 'zod'
import { EmailSchema } from '~/utils/schema.ts'
import { Gradient5 } from '~/components/gradients.tsx'
import { trackEvent } from '~/utils/gtm.client.ts'
import { cn } from '@rouvydev/web-components/utils'
import { useTranslation } from 'react-i18next'
import { Video } from '~/components/video.tsx'
import { NormalizedLink } from '~/components/normalized-link.tsx'

export function prepareHeaderVideoProps(data: HeaderVideoFragment) {
  return {
    headline: data.headline,
    buttons: data.buttons,
    description: data.headerVideoDescription,
    campaignId: data.campaingId,
    subheading: data.subheading,
    anchor: data.headerVideoAnchor,
    video: data.headerVideo,
    videoResponsive: data.headerVideoResponsive,
  }
}

type Props = ReturnType<typeof prepareHeaderVideoProps>

export const RegisterSchema = z.object({
  email: EmailSchema,
})

export function HeaderVideo(props: Props) {
  const { t } = useTranslation()
  const {
    headline,
    buttons,
    description,
    campaignId,
    subheading,
    anchor,
    video,
    videoResponsive,
  } = props
  const fetcher = useFetcher<typeof action>()

  const [form, fields] = useForm({
    id: 'header-form',
    constraint: getFieldsetConstraint(RegisterSchema),
    defaultValue: { email: '' },
    lastSubmission: fetcher.data?.submission,
    onValidate({ formData }) {
      return parse(formData, { schema: RegisterSchema })
    },
    onSubmit(_, { formData }) {
      trackEvent('email_captured', {
        campaignId: campaignId,
        formType: 'lead',
        capturedEmail: formData.get('email'),
      })
    },
    shouldRevalidate: 'onBlur',
  })

  const busy = fetcher.state !== 'idle'

  return (
    <div id={anchor ?? undefined}>
      <div className="relative overflow-hidden bg-background-canvas">
        <Gradient5 className="z-0" />
        <div className="container">
          <div
            className={cn(
              videoResponsive ? 'h-auto ' : 'h-[800px]',
              'relative z-40 flex flex-col gap-4 pt-[88px] md:h-[608px] md:flex-1 md:flex-row lg:h-[800px] lg:pt-[120px]',
            )}
          >
            {videoResponsive && (
              <div className="-mx-8 -mt-28 md:hidden">
                <Video
                  video={videoResponsive}
                  className="-z-10 aspect-square w-full child:!aspect-square"
                  style={{
                    maskImage: "url('/assets/mask-2.svg')",
                    maskRepeat: 'no-repeat',
                    maskSize: 'contain',
                    maskPosition: 'center',
                  }}
                />
              </div>
            )}
            <div className="z-50 -mt-20 flex items-end pb-20 md:items-center md:pb-0">
              <Cols size="6" className="mx-0 w-full">
                <div className="flex flex-col gap-4 md:gap-6 lg:gap-8">
                  {subheading && (
                    <p className="text-center text-[14px] font-semibold text-global-white md:text-left md:text-[20px]">
                      {subheading}
                    </p>
                  )}
                  <h1 className="text-center font-display text-display-40 font-extrabold uppercase italic leading-[40px] text-text-default md:text-left md:text-display-60 md:leading-[60px] lg:text-[4.5rem] lg:leading-[72px]">
                    {headline}
                  </h1>
                  {description && (
                    <p className="text-center text-body-16 font-semibold text-text-muted md:text-left md:text-body-18 lg:text-body-20">
                      {description}
                    </p>
                  )}
                  {campaignId && (
                    <fetcher.Form
                      action="/resources/register"
                      {...form.props}
                      method="POST"
                      className="w-full"
                    >
                      <div className="flex flex-col gap-4 md:flex-row">
                        <div className="flex flex-1 flex-col gap-1">
                          <Input
                            {...conform.input(fields.email)}
                            className="flex-1"
                            placeholder={t('email_input_placeholder')}
                          />
                        </div>
                        <Button
                          type="submit"
                          className="min-w-[140px] justify-center"
                          variant="primary"
                          disabled={busy}
                        >
                          {busy ? t('loading') : t('get_started_button')}
                        </Button>
                      </div>

                      <ErrorList
                        errors={
                          fields.email.errors?.length
                            ? fields.email.errors
                            : ['\u00A0']
                        }
                      />
                      <input
                        type="hidden"
                        name="campaignId"
                        value={campaignId}
                      />
                    </fetcher.Form>
                  )}
                  {!campaignId && buttons && buttons.length > 0 && (
                    <div className="flex gap-4 max-md:flex-col md:flex-wrap">
                      {buttons.map((button, index) => (
                        <NormalizedLink
                          key={index}
                          to={button?.link ?? ''}
                          className={cn(
                            'justify-center',
                            buttonVariants({
                              variant: button?.buttonStyle ?? 'primary',
                            }),
                          )}
                        >
                          {button?.text}
                        </NormalizedLink>
                      ))}
                    </div>
                  )}
                </div>
              </Cols>
            </div>
            <div className="relative -mt-32 max-md:hidden">
              <Video
                video={video}
                className={cn(
                  'absolute -left-72 top-0 aspect-video h-full',
                  videoResponsive ? 'max-lg:hidden' : '',
                )}
                style={{
                  maskImage: "url('/assets/mask-1.svg')",
                  maskRepeat: 'no-repeat',
                  maskSize: 'cover',
                }}
              />
              {videoResponsive && (
                <Video
                  video={videoResponsive}
                  className="absolute -left-32 top-0 aspect-square h-full child:!aspect-square max-md:hidden lg:hidden"
                  style={{
                    maskImage: "url('/assets/mask-2.svg')",
                    maskRepeat: 'no-repeat',
                    maskSize: 'cover',
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
