import type { TestimonialsSectionFragment } from '~/model/api.ts'
import Autoplay from 'embla-carousel-autoplay'
import * as React from 'react'
import {
  Carousel,
  CarouselContent,
  CarouselNextRelative,
  CarouselPreviousRelative,
} from '~/components/carousel.tsx'
import { cn } from '@rouvydev/web-components/utils'

function prepareTestimonialsSectionProps(data: TestimonialsSectionFragment) {
  return {
    anchor: data.testimonialsAnchor ?? undefined,
    testimonials: data.testimonials,
    autoscrollInterval: data.autoscrollInterval,
  }
}

type Props = ReturnType<typeof prepareTestimonialsSectionProps>

function TestimonialsSection({
  anchor,
  testimonials,
  autoscrollInterval,
}: Props) {
  const multiple = testimonials?.length > 1
  const plugins =
    multiple && autoscrollInterval > 0
      ? [
          Autoplay({
            playOnInit: true,
            delay: autoscrollInterval,
            stopOnFocusIn: true,
            stopOnMouseEnter: true,
            stopOnInteraction: true,
          }),
        ]
      : []

  return (
    <div id={anchor}>
      <div className="container">
        <div className="flex justify-center py-12 md:py-16">
          <div className="flex h-[351px] w-full flex-col justify-center gap-6 overflow-hidden bg-background-subtle px-6 py-10 md:h-64 md:flex-row md:p-12 lg:w-5/6">
            <Carousel
              opts={{
                dragFree: false,
                loop: true,
                align: 'start',
              }}
              plugins={plugins}
            >
              <div className="flex h-full flex-col justify-between gap-6 md:flex-row md:justify-center">
                {multiple && (
                  <div className="my-auto hidden shrink-0 md:block">
                    <CarouselPreviousRelative size="big" />
                  </div>
                )}
                <CarouselContent className="-pl-10 ml-0 min-w-0">
                  {testimonials?.map((testimonial, index) => (
                    <div
                      key={index}
                      className={cn(
                        'flex-[0_0_90%] md:flex-[0_0_100%]',
                        multiple && 'ml-40 md:ml-10',
                      )}
                    >
                      <div className="flex flex-col gap-6 text-left md:gap-8 md:text-center">
                        <span className="font-display text-display-28 font-extrabold italic leading-7 text-global-white md:text-display-40 md:leading-10">
                          {testimonial?.testimonial}
                        </span>
                        <div className="flex flex-col gap-1">
                          <span className="text-body-14 font-semibold leading-7 text-global-white md:text-body-18">
                            {testimonial?.author}
                          </span>
                          <span className="text-body-12 font-medium leading-4 text-text-muted md:text-body-14">
                            {testimonial?.authorType}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </CarouselContent>
                {multiple && (
                  <div className="my-auto hidden shrink-0 md:block">
                    <CarouselNextRelative size="big" />
                  </div>
                )}
                {multiple && (
                  <div className="flex flex-row gap-3 md:hidden">
                    <CarouselPreviousRelative size="big" />
                    <CarouselNextRelative size="big" />
                  </div>
                )}
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  )
}

export { prepareTestimonialsSectionProps, TestimonialsSection }
