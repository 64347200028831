import type { NavbarFragment } from '~/model/api.ts'
import { Cols } from '~/components/cols.tsx'
import { NavLink } from '@remix-run/react'

function prepareNavbarProps(data: NavbarFragment) {
  return {
    anchorId: data.navbarAnchor,
    items: data.navbarItems?.map(item => ({
      title: item?.title,
      path: item?.path,
    })),
  }
}

type Props = ReturnType<typeof prepareNavbarProps>

function Navbar({ anchorId, items }: Props) {
  return (
    <div id={anchorId ?? undefined}>
      <div className="container">
        <Cols size="10">
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-4">
            {items &&
              items
                .filter(item => item.path)
                .map((item, index) => (
                  <NavLink
                    to={item.path ?? ''}
                    key={index}
                    className={({ isActive }) =>
                      `${
                        isActive
                          ? 'border-4 border-global-electric-violet-700'
                          : ''
                      } h-40`
                    }
                  >
                    <div className=" h-full bg-global-dark-ink-blue-500 hover:bg-global-electric-indigo-800">
                      <div className="flex h-full items-center justify-center">
                        <span className="p-4 text-center text-body-24 font-extrabold uppercase italic leading-6 text-global-white">
                          {item.title}
                        </span>
                      </div>
                    </div>
                  </NavLink>
                ))}
          </div>
        </Cols>
      </div>
    </div>
  )
}

export { prepareNavbarProps, Navbar }
