import { cn } from '@rouvydev/web-components/utils'
import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'
import { Button } from '@rouvydev/web-components/basics'
import { Icon } from '~/components/icon.tsx'
import { useState } from 'react'

const alertVariants = cva(
  'relative flex items-start justify-between gap-8 px-4 py-3 text-center text-body-16 font-medium leading-5',
  {
    variants: {
      variant: {
        'promo-yellow': 'bg-background-accent text-text-on-color',
        'promo-indigo': 'bg-background-inset text-text-default',
        warning: 'bg-background-warning text-text-on-color',
        success: 'bg-global-eucalyptus-700 text-text-on-color',
        error: 'bg-background-negative text-text-default',
        info: 'bg-background-informative text-text-on-color',
      },
    },
    defaultVariants: {
      variant: 'info',
    },
  },
)

export type AlertProps = VariantProps<typeof alertVariants> & {
  message: string
  className?: string
  closeable?: boolean
}

export function Alert(props: AlertProps) {
  const { className, variant, message, closeable = true } = props
  const [active, setActive] = useState(true)

  if (!active) {
    return null
  }

  return (
    <div
      role="alert"
      data-testid="alert-message"
      className={cn(alertVariants({ variant, className }))}
    >
      <span className="block w-full pr-4 text-center">{message}</span>
      {closeable && (
        <Button
          type="button"
          onClick={() => setActive(false)}
          className="absolute right-0 mr-4 p-0 hover:text-text-on-color/60"
          variant="subtle"
        >
          <Icon name="close" />
        </Button>
      )}
    </div>
  )
}
