import type { SpotlightsListItemFragment } from '~/model/api.ts'
import { NormalizedLink } from '~/components/normalized-link.tsx'
import { ThumborUrlBuilder } from 'thumbor-url-builder-ts'
import { cn } from '@rouvydev/web-components/utils'
import { cardVariants } from '@rouvydev/web-components/content'

const thumbor = new ThumborUrlBuilder(ENV.THUMBOR_SECURITY, ENV.THUMBOR_URL)

export function SpotlightCard({
  title,
  subtitle,
  button,
  backgroundImage,
}: SpotlightsListItemFragment) {
  const backgroundImageUrl = backgroundImage?.data?.attributes?.url
    ? thumbor
        .setImagePath(encodeURIComponent(backgroundImage.data?.attributes?.url))
        .resize(304, 304)
        .smartCrop(true)
        .buildUrl()
    : ''

  const spotlightContent = (
    <article
      className={cn(
        'relative z-10 bg-global-dark-ink-blue-300',
        cardVariants.md.size,
      )}
      style={{
        background: backgroundImageUrl
          ? `linear-gradient(1deg, rgba(17, 7, 40, 0.00) 29.93%, rgba(17, 7, 40, 0.80) 99.03%), url(${backgroundImageUrl}), lightgray 50% / cover no-repeat`
          : '',
      }}
    >
      <div className="inset-0 z-30 flex flex-col gap-2 p-8">
        <h4 className="line-clamp-2 font-display text-display-28 font-extrabold uppercase italic leading-8 text-text-default lg:text-body-32">
          {title}
        </h4>
        <h5 className="text-[14px] text-text-default lg:text-[16px]">
          {subtitle}
        </h5>
      </div>
    </article>
  )

  return (
    <div className="relative h-[312px] w-[312px]">
      <span
        className="absolute -bottom-2 -right-2 z-0 m-2 h-[304px] w-[304px]"
        style={{
          background:
            'linear-gradient(180deg, rgba(53, 13, 118, 0.40) 0%, #350D76 100%)',
        }}
      ></span>
      {button?.link ? (
        <NormalizedLink to={button.link} className="relative">
          {spotlightContent}
        </NormalizedLink>
      ) : (
        spotlightContent
      )}
    </div>
  )
}
