import type { RoutesSectionFragment } from '~/model/api.ts'
import { RouteCard } from '~/components/collections/route-card.tsx'
import _ from 'lodash'
import { DynamicZoneSectionGradients } from '~/components/ui/section-gradients.tsx'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '~/components/carousel.tsx'
import { cn } from '@rouvydev/web-components/utils'

function prepareRoutesSectionProps(data: RoutesSectionFragment) {
  return {
    routes: _.union(data.routeIds?.list, data.routes),
    title: data.routesTitle,
    anchor: data.routesAnchor,
    expand: data.expand,
  }
}
type Props = ReturnType<typeof prepareRoutesSectionProps>
function RoutesSection({ title, routes, anchor, expand }: Props) {
  return routes?.length ? (
    <div className="relative bg-global-dark-ink-blue-200">
      <DynamicZoneSectionGradients />
      <div id={anchor ?? undefined}>
        <div className="flex flex-col gap-20 py-20">
          <h3 className="block-subtitle container">{title}</h3>
          <Carousel
            className="px-4"
            opts={{
              dragFree: true,
            }}
          >
            <CarouselContent>
              {routes.map((route, index) => (
                <CarouselItem
                  key={index}
                  className={cn('basis-auto pl-8', index === 0 && 'pl-0')}
                >
                  <RouteCard
                    key={route.id}
                    {...route}
                    expand={expand === true}
                  />
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
          </Carousel>
        </div>
      </div>
    </div>
  ) : null
}

export { RoutesSection, prepareRoutesSectionProps }
