import type {
  BlogPostListItemFragment,
  BlogPostsFragment,
} from '~/model/api.ts'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '~/components/carousel.tsx'
import { cn } from '@rouvydev/web-components/utils'
import { BlogPostCard } from '~/components/collections/blog-post-card.tsx'

function prepareBlogPostsProps(data: BlogPostsFragment) {
  return {
    title: data.blogPostsTitle,
    anchor: data.blogPostsAnchor,
    expand: data.expand,
    blogPosts: data.blogPosts?.data,
  }
}

type Props = ReturnType<typeof prepareBlogPostsProps>

function BlogPosts({ title, anchor, expand, blogPosts }: Props) {
  return (
    <div className="relative bg-global-dark-ink-blue-200">
      <div id={anchor ?? undefined}>
        <div className="flex flex-col gap-20 py-20">
          <h3 className="block-subtitle container">{title}</h3>
          {blogPosts?.length ? (
            <Carousel
              className="px-4"
              opts={{
                dragFree: true,
              }}
            >
              <CarouselContent>
                {blogPosts?.map((item, index) => (
                  <CarouselItem
                    key={index}
                    className={cn('basis-auto pl-8', index === 0 && 'pl-0')}
                  >
                    <BlogPostCard
                      {...(item.attributes as BlogPostListItemFragment)}
                      expand={expand === true}
                    />
                  </CarouselItem>
                ))}
              </CarouselContent>
              <CarouselPrevious />
              <CarouselNext />
            </Carousel>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export { prepareBlogPostsProps, BlogPosts }
