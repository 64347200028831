import { Cols } from '~/components/cols.tsx'
import { Article } from '~/components/article.tsx'
import { Markdown } from '~/components/markdown.tsx'
import type { TextBlockFragment } from '~/model/api.ts'
import type { RenderableTreeNodes } from '@markdoc/markdoc'

type TransformedTextBlock = {
  transformedText: RenderableTreeNodes
} & TextBlockFragment

function prepareTextBlockProps(data: TextBlockFragment) {
  const transformedObject = data as TransformedTextBlock

  return {
    text: transformedObject.transformedText,
  }
}

type Props = {
  text: RenderableTreeNodes
}

function TextBlock({ text }: Props) {
  return (
    <div>
      <div className="container">
        <Cols size="8">
          <Article>
            <Markdown content={text} />
          </Article>
        </Cols>
      </div>
    </div>
  )
}

export { prepareTextBlockProps, TextBlock }
