import { Faq, prepareFaqProps } from './faq/faq.tsx'
import { preparePrizesCtaProps, Prizes } from './prizes/prizes.tsx'
import {
  NewsletterCta,
  prepareNewsletterCtaProps,
} from './newsletter-cta/newsletter-cta.tsx'
import { Highlights, prepareHighlightsProps } from './highlights/highlights.tsx'
import { prepareSimpleCtaProps, SimpleCta } from './simple-cta/simple-cta.tsx'
import { Events, prepareEventsProps } from './events/events.tsx'
import {
  CompanionCta,
  prepareCompanionCtaProps,
} from '~/components/dynamic-zone/companion-cta/companion-cta.tsx'
import {
  PageNavigation,
  preparePageNavigationProps,
} from './page-navigation/page-navigation.tsx'
import {
  prepareRegisterCtaProps,
  RegisterCta,
} from '~/components/dynamic-zone/register-cta/register-cta.tsx'
import {
  prepareRoutesSectionProps,
  RoutesSection,
} from '~/components/dynamic-zone/routes-section/routes-section.tsx'
import {
  Navbar,
  prepareNavbarProps,
} from '~/components/dynamic-zone/navbar/navbar.tsx'
import {
  prepareTextBlockProps,
  TextBlock,
} from '~/components/dynamic-zone/text-block/text-block.tsx'

import {
  prepareStripeProps,
  Stripe,
} from '~/components/dynamic-zone/stripe/stripe.tsx'
import {
  prepareStepsProps,
  Steps,
} from '~/components/dynamic-zone/steps/steps.tsx'
import type {
  BlogDynamicBodyFragment,
  DynamicPageBodyFragment,
  HomepageBodyFragment,
} from '~/model/api.ts'
import { prepareYoutubeProps, Youtube } from './youtube/youtube.tsx'
import {
  LinksCarousel,
  prepareLinksCarouselProps,
} from '~/components/dynamic-zone/links-carousel/links-carousel.tsx'
import {
  Header,
  prepareHeaderProps,
} from '~/components/dynamic-zone/header/header.tsx'
import {
  HeaderAcceptInvite,
  prepareHeaderAcceptInviteProps,
} from '~/components/dynamic-zone/header-accept-invite/header-accept-invite.tsx'
import {
  prepareSpotlightsProps,
  Spotlights,
} from '~/components/dynamic-zone/spotlights/spotlights.tsx'
import {
  Challenges,
  prepareChallengesProps,
} from '~/components/dynamic-zone/challenges/challenges.tsx'
import {
  BlogPosts,
  prepareBlogPostsProps,
} from '~/components/dynamic-zone/blog-posts/blog-posts.tsx'
import {
  prepareSpotlightsListProps,
  SpotlightsList,
} from '~/components/dynamic-zone/spotlights-list/spotlights-list.tsx'
import {
  HeaderVideo,
  prepareHeaderVideoProps,
} from '~/components/dynamic-zone/header-video/header-video.tsx'
import {
  prepareWorkoutsSectionProps,
  WorkoutsSection,
} from '~/components/dynamic-zone/workouts-section/workouts-section.tsx'
import {
  preparePricingProps,
  Pricing,
} from '~/components/dynamic-zone/pricing/pricing.tsx'
import {
  ContentTeaser,
  prepareContentTeaserProps,
} from '~/components/dynamic-zone/content-teaser/content-teaser.tsx'
import {
  Checklist,
  prepareChecklistProps,
} from '~/components/dynamic-zone/checklist/checklist.tsx'
import {
  prepareProductsComparisonProps,
  ProductsComparison,
} from '~/components/dynamic-zone/products-comparison/products-comparison.tsx'
import {
  PlansSelector,
  preparePlansSelectorProps,
} from '~/components/dynamic-zone/plans-selector/plans-selector.tsx'
import {
  prepareTestimonialsSectionProps,
  TestimonialsSection,
} from '~/components/dynamic-zone/testimonials-section/testimonials-section.tsx'

export function DynamicZone({
  unionMembers,
}: {
  unionMembers?:
    | HomepageBodyFragment[]
    | DynamicPageBodyFragment[]
    | BlogDynamicBodyFragment[]
    | null
}) {
  if (!unionMembers || unionMembers.length <= 0) {
    return null
  }

  return (
    <div className="dz-container">
      {unionMembers.map((unionMember, index) => {
        switch (unionMember?.__typename) {
          case 'ComponentSharedFaq':
            return <Faq key={index} {...prepareFaqProps(unionMember)} />
          case 'ComponentSharedPrizes':
            return (
              <Prizes {...preparePrizesCtaProps(unionMember)} key={index} />
            )
          case 'ComponentSharedNewsletterCta':
            return (
              <NewsletterCta
                {...prepareNewsletterCtaProps(unionMember)}
                key={index}
              />
            )
          case 'ComponentSharedHeaderAcceptInvite':
            return (
              <HeaderAcceptInvite
                {...prepareHeaderAcceptInviteProps(unionMember)}
                key={index}
              />
            )
          case 'ComponentSharedHighlights':
            return (
              <Highlights
                {...prepareHighlightsProps(unionMember)}
                key={index}
              />
            )
          case 'ComponentSharedHeader':
            return <Header {...prepareHeaderProps(unionMember)} key={index} />
          case 'ComponentSharedSimpleCta':
            return (
              <SimpleCta {...prepareSimpleCtaProps(unionMember)} key={index} />
            )
          case 'ComponentSharedEvents':
            return <Events key={index} {...prepareEventsProps(unionMember)} />
          case 'ComponentSharedCompanionAppCta':
            return (
              <CompanionCta
                {...prepareCompanionCtaProps(unionMember)}
                key={index}
              />
            )
          case 'ComponentSharedPageNavigation':
            return (
              <PageNavigation
                {...preparePageNavigationProps(unionMember)}
                key={index}
              />
            )
          case 'ComponentSharedRegisterCta':
            return (
              <RegisterCta
                {...prepareRegisterCtaProps(unionMember)}
                key={index}
              />
            )
          case 'ComponentSharedRoutesSection':
            return (
              <RoutesSection
                key={index}
                {...prepareRoutesSectionProps(unionMember)}
              />
            )
          case 'ComponentSharedNavbar':
            return <Navbar key={index} {...prepareNavbarProps(unionMember)} />
          case 'ComponentSharedLinksCarousel':
            return (
              <LinksCarousel
                key={index}
                {...prepareLinksCarouselProps(unionMember)}
              />
            )
          case 'ComponentSharedYouTubeVideo':
            return <Youtube {...prepareYoutubeProps(unionMember)} key={index} />
          case 'ComponentSharedStripe':
            return <Stripe key={index} {...prepareStripeProps(unionMember)} />
          case 'ComponentSharedSteps':
            return <Steps {...prepareStepsProps(unionMember)} key={index} />
          case 'ComponentSharedTextBlock':
            return (
              <TextBlock key={index} {...prepareTextBlockProps(unionMember)} />
            )
          case 'ComponentSharedSpotlights':
            return (
              <Spotlights
                key={index}
                {...prepareSpotlightsProps(unionMember)}
              />
            )
          case 'ComponentSharedChallenges':
            return (
              <Challenges
                key={index}
                {...prepareChallengesProps(unionMember)}
              />
            )
          case 'ComponentSharedBlogPosts':
            return (
              <BlogPosts key={index} {...prepareBlogPostsProps(unionMember)} />
            )
          case 'ComponentSharedSpotlightsList':
            return (
              <SpotlightsList
                key={index}
                {...prepareSpotlightsListProps(unionMember)}
              />
            )
          case 'ComponentSharedHeaderVideo':
            return (
              <HeaderVideo
                key={index}
                {...prepareHeaderVideoProps(unionMember)}
              />
            )
          case 'ComponentSharedWorkoutsSection':
            return (
              <WorkoutsSection
                key={index}
                {...prepareWorkoutsSectionProps(unionMember)}
              />
            )
          case 'ComponentSharedPricing':
            return <Pricing key={index} {...preparePricingProps(unionMember)} />
          case 'ComponentSharedContentTeaser':
            return (
              <ContentTeaser
                key={index}
                {...prepareContentTeaserProps(unionMember)}
              />
            )
          case 'ComponentSharedChecklist':
            return (
              <Checklist key={index} {...prepareChecklistProps(unionMember)} />
            )
          case 'ComponentSharedProductsComparison':
            return (
              <ProductsComparison
                key={index}
                {...prepareProductsComparisonProps(unionMember)}
              />
            )
          case 'ComponentSharedPlansSelector':
            return (
              <PlansSelector
                key={index}
                {...preparePlansSelectorProps(unionMember)}
              />
            )
          case 'ComponentSharedTestimonialsSection':
            return (
              <TestimonialsSection
                key={index}
                {...prepareTestimonialsSectionProps(unionMember)}
              />
            )
          default:
            return null
        }
      })}
    </div>
  )
}
