import type { ContentTeaserFragment } from '~/model/api.ts'
import { useTranslation } from 'react-i18next'
import { NormalizedLink } from '~/components/normalized-link.tsx'
import { Image } from '~/components/image.tsx'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from '~/components/carousel.tsx'
import { cn } from '@rouvydev/web-components/utils'

export function prepareContentTeaserProps(data: ContentTeaserFragment) {
  return {
    title: data.teaserTitle,
    description: data.teaserDescription,
    bgImage: data.backgroundImage,
    bgImageResponsive: data.backgroundImageResponsive,
    cards: data.cards,
    size: data.size,
    anchor: data.teaserAnchor,
    logo: data.logo,
    logoResponsive: data.logoResponsive,
  }
}

type Props = ReturnType<typeof prepareContentTeaserProps>

export function ContentTeaser(props: Props) {
  const { t } = useTranslation()

  return (
    <div
      className="overflow-hidden pb-20 pt-10 md:py-24"
      id={props.anchor ?? undefined}
    >
      <div className="container relative">
        <div className="absolute -top-10 -ml-4 h-full w-full md:-right-24 md:-top-24 lg:right-[-430px]">
          <Image
            src={props.bgImage?.data?.attributes?.url ?? ''}
            role="presentation"
            alt={props.bgImage?.data?.attributes?.alternativeText ?? ''}
            className="teaser-mask-sm md:teaser-mask h-full w-full max-w-none object-cover md:h-[743px] md:w-[1056px]"
            skipSmallVariants
          />
        </div>
        <div className="relative grid grid-cols-1 pb-10 pt-10 text-center md:grid-cols-6 md:pb-32 md:pt-32 md:text-left lg:grid-cols-5">
          <div className="col-span-1 md:col-span-3 lg:col-span-2">
            <h3 className="mb-4 font-display text-display-40 uppercase italic text-global-white md:text-display-48 lg:mb-6">
              {props.title}
            </h3>
            <p className="text-center text-body-16 font-semibold text-text-muted md:text-left md:text-body-18 lg:text-body-20">
              {props.description}
            </p>
          </div>
          <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-3">
            <div className="pt-44 text-center md:pt-20">
              <Image
                src={props.logo?.data?.attributes?.url ?? ''}
                role="presentation"
                alt={props.logo?.data?.attributes?.alternativeText ?? ''}
                className="mb-3 hidden max-w-[180px] md:inline-block md:max-w-[220px] lg:max-w-[270px]"
              />
              <Image
                src={props.logoResponsive?.data?.attributes?.url ?? ''}
                role="presentation"
                alt={
                  props.logoResponsive?.data?.attributes?.alternativeText ?? ''
                }
                className="mb-3 inline-block max-w-[180px] md:hidden"
              />
              {props.logo?.data?.attributes?.alternativeText && (
                <span className="text-base md:text-xl font-[600]">
                  {t('latest_spotlight')}
                </span>
              )}
            </div>
          </div>
        </div>
        <Carousel
          opts={{
            dragFree: true,
            slidesToScroll: 'auto',
          }}
        >
          <CarouselContent>
            {props.cards &&
              props.cards.length > 0 &&
              props.cards.map((card, index) =>
                card?.url ? (
                  <CarouselItem
                    key={index}
                    className={cn('basis-auto pl-4', index === 0 && 'pl-0')}
                  >
                    <NormalizedLink to={card.url}>
                      <TeaserCard
                        title={card?.title ?? ''}
                        description={card?.description ?? ''}
                        bgImageUrl={card?.cover.data?.attributes?.url ?? ''}
                        key={index}
                      />
                    </NormalizedLink>
                  </CarouselItem>
                ) : (
                  <CarouselItem
                    key={index}
                    className={cn('basis-auto pl-4', index === 0 && 'pl-0')}
                  >
                    <TeaserCard
                      title={card?.title ?? ''}
                      description={card?.description ?? ''}
                      bgImageUrl={card?.cover.data?.attributes?.url ?? ''}
                      key={index}
                    />
                  </CarouselItem>
                ),
              )}
          </CarouselContent>
        </Carousel>
      </div>
    </div>
  )
}

function TeaserCard(props: {
  bgImageUrl: string
  title: string
  description: string
}) {
  return (
    <article className="relative flex h-80 w-60 flex-col justify-end overflow-hidden bg-global-dark-ink-blue-300">
      <Image
        src={props.bgImageUrl ?? ''}
        role="presentation"
        alt={props.title}
        className="teaser-card-mask absolute top-0 h-60 w-full"
      />

      <div className="relative flex h-full flex-col justify-end gap-2 px-6 py-10">
        <span className="font-sans text-uppercase-12 uppercase tracking-[6px] text-text-muted">
          {props.description}
        </span>
        <h3 className="font-display text-display-28 uppercase italic text-global-white ">
          {props.title}
        </h3>
      </div>
    </article>
  )
}
